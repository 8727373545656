
import VueI18n from "vue-i18n";
import { ERROR_LIST } from "@/helpers/errors";
import ResendEmailConfirmation from "@/components/ResendEmailConfirmation.vue";
import { SPACES, SPACES_HOME } from "@/router";
import { Validation, validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import vuelidateMixin from "@/helpers/vuelidateMixin";
import { ValidationGroups } from "vue/types/vue";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import TranslateResult = VueI18n.TranslateResult;

interface Data {
  username: string;
  password: string;
  showError: boolean;
  messageError: TranslateResult;
  showPassword: boolean;
  submitted: boolean;
  confirmEmail: boolean;
}

interface Method {
  login(): void;
  cleanError(): void;
}
interface Computed {
  usernameError: TranslateResult[];
  mdiEye: string;
  mdiEyeOff: string;
}

export default vuelidateMixin.extend<
  Data,
  Method,
  Computed,
  Record<string, never>
>({
  name: 'Login',
  mixins: [validationMixin, vuelidateMixin],
  components: { ResendEmailConfirmation },
  validations: {
    username: { required, email },
    password: { required }
  },
  data() {
    return {
      username: '',
      password: '',
      messageError: '',
      showError: false,
      showPassword: false,
      confirmEmail: false,
      submitted: false
    };
  },
  methods: {
    login() {
      this.$v.$touch();
      if (!this.$v.$invalid && !this.$v.$pending) {
        this.submitted = true;
        this.confirmEmail = false;
        this.showError = false;
        this.messageError = '';
        const { username, password } = this;
        if (username && password) {
          this.$store
            .dispatch('UserModule/login', {
              email: username,
              password: password
            })
            .then(({ status, space }) => {
              this.submitted = false;
              if (this.$route.query && this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect.toString());
              } else if (status === 'spaceHome') {
                this.$router.push({
                  name: SPACES_HOME,
                  params: { spaceId: space.id }
                });
              } else if (status === 'spaceSelect') {
                this.$router.push({ name: SPACES });
              }
            })
            .catch((error) => {
              console.log(error);
              this.submitted = false;
              this.showError = true;
              if (error.errorStack) {
                this.messageError = error.errorStack.message;
              } else {
                this.messageError = error.description;
              }
              if (error === ERROR_LIST.LOGIN_NOT_CONFIRMED) {
                this.confirmEmail = true;
              }
            });
        }
      }
    },
    cleanError() {
      this.showError = false;
      this.messageError = '';
    }
  },
  computed: {
    mdiEye() {
      return mdiEye;
    },
    mdiEyeOff() {
      return mdiEyeOff;
    },
    usernameError() {
      const errors: TranslateResult[] = [];
      const field = this.$v.username as ValidationGroups & Validation;
      if (!field.$dirty) return [];
      const reqField = this.required(field);
      reqField && errors.push(reqField);
      !field.email && errors.push(this.$t('login.login.error.email'));
      return errors;
    }
  }
});
