
import Vue from 'vue';
import Registration from '@/components/login/Registration.vue';
import ForgotPass from '@/components/login/ForgotPass.vue';
import LoginComponent from '@/components/login/Login.vue';

type LoginC = Vue & {
  cleanError(): void;
};

interface Data {
  step: number;
}

export default Vue.extend<
  Data,
  Record<string, never>,
  Record<string, never>,
  Record<string, never>
>({
  name: 'Login',
  components: {
    LoginComponent,
    ForgotPass,
    Registration
  },
  data() {
    return {
      step: 1
    };
  },
  async created() {
    if (this.$route.query && this.$route.query.invite) {
      this.step = 2;
    }
  },
  watch: {
    step(newValue, oldValue) {
      let ref = null;
      switch (oldValue) {
        case 1:
          ref = this.$refs.login as LoginC;
          break;
        case 2:
          ref = this.$refs.registration as LoginC;
          break;
        case 3:
          ref = this.$refs.forgotPass as LoginC;
          break;
      }
      if (ref) {
        ref.cleanError();
      }
    }
  }
});
