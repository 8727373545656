
import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import { mask } from 'vue-the-mask';
import { email, required } from 'vuelidate/lib/validators';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;
import axios from 'axios';
import { FORGOT_PASS_URL } from '@/store/constants';

interface Data {
  email: string;
  showErrorForgot: boolean;
  messageErrorForgot: TranslateResult;
  submitted: boolean;
  step: number;
  sentResetEmail: boolean;
}

interface Method {
  forgotPass(): void;

  cleanError(): void;
}

interface Computed {
  emailValidation: TranslateResult[];
}

export default Vue.extend<Data, Method, Computed, Record<string, never>>({
  name: 'ForgotPass',
  mixins: [validationMixin],
  directives: { mask },
  validations: {
    email: { required, email }
  },
  data() {
    return {
      email: '',
      messageErrorForgot: '',
      showErrorForgot: false,
      showPassword: false,
      submitted: false,
      step: 1,
      sentResetEmail: false
    };
  },
  methods: {
    forgotPass() {
      this.submitted = true;
      this.sentResetEmail = false;
      if (!this.$v.email.$anyError && this.$v.email.$anyDirty) {
        axios
          .post(FORGOT_PASS_URL, { email: this.email })
          .then(() => {
            this.sentResetEmail = true;
            this.submitted = false;
          })
          .catch((error) => {
            this.showErrorForgot = true;
            this.submitted = false;
            if (error && error.response) {
              if (error.response.status === 400) {
                this.messageErrorForgot = this.$t(
                  'login.forgot.errorMessages.missingEmail'
                );
              } else if (error.response.status === 500) {
                this.messageErrorForgot = this.$t(
                  'login.forgot.errorMessages.generic'
                );
              }
            } else {
              this.messageErrorForgot = this.$t(
                'login.forgot.errorMessages.generic'
              );
              this.$rollbar.error('Error', error);
            }
          });
      } else {
        this.showErrorForgot = true;
        this.messageErrorForgot = this.$t(
          'login.forgot.errorMessages.missingEmail'
        );
        this.submitted = false;
      }
    },
    cleanError() {
      this.showErrorForgot = false;
      this.messageErrorForgot = '';
    }
  },
  computed: {
    emailValidation() {
      const errors: TranslateResult[] = [];
      if (!this.$v.email?.$dirty) return errors;
      !this.$v.email.required &&
        errors.push(this.$t('login.register.errorMessages.requiredField'));
      !this.$v.email.email &&
        errors.push(this.$t('login.register.errorMessages.emailMustBeValid'));
      return errors;
    }
  }
});
