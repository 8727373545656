
import Vue from 'vue';
import axios from 'axios';
import { CONFIRM_EMAIL_URL } from '@/store/constants';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;
import ResendEmailConfirmation from '@/components/ResendEmailConfirmation.vue';

interface Data {
  code: string;
  confirmed: boolean;
  verifying: boolean;
  submitted: boolean;
  confirmEmail: boolean;
  messageError: TranslateResult;
}

export default Vue.extend<
  Data,
  Record<string, never>,
  Record<string, never>,
  Record<string, never>
>({
  name: 'EmailConfirmation',
  components: { ResendEmailConfirmation },
  data() {
    return {
      code: '',
      confirmed: false,
      verifying: true,
      confirmEmail: false,
      submitted: false,
      messageError: ''
    };
  },
  async created() {
    this.code = this.$router.currentRoute.params.code;
    if (this.code) {
      axios
        .get(CONFIRM_EMAIL_URL + '?code=' + encodeURIComponent(this.code))
        .then(() => {
          this.confirmed = true;
          this.verifying = false;
          this.confirmEmail = false;
        })
        .catch((error) => {
          this.confirmed = false;
          this.verifying = false;
          this.confirmEmail = false;
          if (error && error.response) {
            if (error.response.status === 422) {
              this.messageError = this.$t(
                'emailConfirmation.errorMessages.invalidCode'
              );
            } else if (error.response.status === 400) {
              this.messageError = this.$t(
                'emailConfirmation.errorMessages.invalidCode'
              );
            } else if (error.response.status === 409) {
              this.messageError = this.$t(
                'emailConfirmation.errorMessages.codeNotExist'
              );
            } else if (error.response.status === 410) {
              this.messageError = this.$t(
                'emailConfirmation.errorMessages.codeExpired'
              );
              this.confirmEmail = true;
            } else if (error.response.status === 500) {
              this.messageError = this.$t(
                'emailConfirmation.errorMessages.generic'
              );
            }
          } else {
            this.messageError = this.$t(
              'emailConfirmation.errorMessages.generic'
            );
            this.$rollbar.error('Error', error);
          }
        });
    } else {
      this.confirmed = false;
      this.verifying = false;
      this.messageError = this.$t('emailConfirmation.errorMessages.noCode');
    }
  }
});
