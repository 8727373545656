import { render, staticRenderFns } from "./ResendEmailConfirmation.vue?vue&type=template&id=53c03330&scoped=true"
import script from "./ResendEmailConfirmation.vue?vue&type=script&lang=ts"
export * from "./ResendEmailConfirmation.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53c03330",
  null
  
)

export default component.exports