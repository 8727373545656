
import Vue from 'vue';
import axios from 'axios';
import {
  RESEND_EMAIL_CONFIRMATION_CODE_URL,
  RESEND_EMAIL_CONFIRMATION_EMAIL_URL
} from '@/store/constants';

interface Data {
  confirmEmail: boolean;
  confirmEmailSent: boolean;
  submitted: boolean;
}

interface Props {
  code: string;
  email: string;
}

interface Methods {
  resendEmail(): void;
}

export default Vue.extend<Data, Methods, Record<string, never>, Props>({
  name: 'ResendEmailConfirmation',
  data() {
    return {
      confirmEmail: true,
      confirmEmailSent: false,
      submitted: false
    };
  },
  methods: {
    resendEmail() {
      this.submitted = true;
      let url = '';
      if (this.$props.code) {
        url =
          RESEND_EMAIL_CONFIRMATION_CODE_URL +
          '?code=' +
          encodeURIComponent(this.$props.code);
      } else if (this.$props.email) {
        url =
          RESEND_EMAIL_CONFIRMATION_EMAIL_URL +
          '?email=' +
          encodeURIComponent(this.$props.email);
      }
      if (url) {
        axios
          .get(url)
          .then(() => {
            this.confirmEmail = false;
            this.submitted = false;
            this.confirmEmailSent = true;
          })
          .catch((error) => {
            this.confirmEmail = false;
            this.submitted = false;
            this.confirmEmailSent = true;
            this.$rollbar.error(error);
          });
      } else {
        this.confirmEmail = false;
        this.submitted = false;
        this.confirmEmailSent = true;
      }
    }
  },
  props: {
    code: {
      type: String
    },
    email: {
      type: String
    }
  }
});
