
import Vue from 'vue';
import { Validation, validationMixin } from 'vuelidate';
import { mask } from 'vue-the-mask';
import {
  alpha,
  email,
  minLength,
  or,
  required,
  requiredIf,
  sameAs
} from 'vuelidate/lib/validators';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import { REGISTER_URL } from '@/store/constants';
import {
  alphaAndSpaces,
  cellphone,
  mustHaveAlphaCaps,
  mustHaveAlphaNonCaps,
  mustHaveNumber,
  mustHaveSpecialChar,
  PASSWORD_MIN_LENGTH,
  phone
} from '@/helpers/validations';
import { publicConfig } from '@/helpers/platformConfiguration';
import DatePicker from '@/components/horse/DatePicker.vue';
import { mdiEye, mdiEyeOff, mdiPhone } from '@mdi/js';
import TranslateResult = VueI18n.TranslateResult;
import { ValidationGroups } from 'vue/types/vue';

interface Data {
  register: {
    name: string;
    lastName: string;
    phone: string;
    dob: string;
    email: string;
    password: string;
    passwordConfirmation: string;
    invite: string;
  };
  valid: boolean;
  isTermsAccepted: boolean;
  showErrorRegister: boolean;
  messageErrorRegister: TranslateResult;
  registered: boolean;
  showPasswordReg: boolean;
  showPasswordRegConf: boolean;
  submitted: boolean;
  inviteActive: boolean;
}

interface Method {
  signup(): void;

  cleanError(): void;

  selectedDate(date: string): void;
}

interface Computed {
  registerNameValidation: TranslateResult[];
  registerLastNameValidation: TranslateResult[];
  registerEmailValidation: TranslateResult[];
  registerPhoneValidation: TranslateResult[];
  registerDoBValidation: TranslateResult[];
  registerPasswordValidation: TranslateResult[];
  registerPasswordConfValidation: TranslateResult[];
  registerInviteValidation: TranslateResult[];
  mdiEye: string;
  mdiEyeOff: string;
  mdiPhone: string;
}

type ComponentType = Vue & Data & Method & Computed;

export default Vue.extend<Data, Method, Computed, Record<string, never>>({
  name: 'Registration',
  mixins: [validationMixin],
  components: { DatePicker },
  directives: { mask },
  validations: {
    register: {
      name: { required, alpha },
      lastName: { required, alphaAndSpaces },
      phone: { required, validFormat: or(phone, cellphone) },
      dob: { required },
      email: { required, email },
      password: {
        required,
        minLength: minLength(PASSWORD_MIN_LENGTH),
        mustHaveAlphaCaps,
        mustHaveAlphaNonCaps,
        mustHaveNumber,
        mustHaveSpecialChar
      },
      passwordConfirmation: { required, sameAsPassword: sameAs('password') },
      invite: {
        required: requiredIf(function (this: ComponentType) {
          return this.inviteActive;
        })
      }
    }
  },
  data() {
    return {
      register: {
        name: '',
        lastName: '',
        phone: '',
        dob: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        invite: ''
      },
      valid: false,
      isTermsAccepted: false,
      messageErrorRegister: '',
      showErrorRegister: false,
      registered: false,
      showPassword: false,
      showPasswordReg: false,
      showPasswordRegConf: false,
      submitted: false,
      confirmEmail: false,
      inviteActive: false
    };
  },
  async created() {
    const platformConfig = await publicConfig.get();
    if (platformConfig) {
      this.inviteActive = platformConfig.INVITE_ONLY;
    }
    if (this.$route.query && this.$route.query.name) {
      this.register.name = this.$route.query.name.toString();
    }
    if (this.$route.query && this.$route.query.email) {
      this.register.email = this.$route.query.email.toString();
    }
    if (this.$route.query && this.$route.query.invite) {
      this.register.invite = this.$route.query.invite.toString();
    }
    this.showErrorRegister = false;
    this.messageErrorRegister = '';
  },
  methods: {
    signup() {
      this.$v.register.$touch();
      const datePickerV = (this.$refs.datePicker as Validation).$v;
      datePickerV.$touch();
      this.submitted = true;
      this.showErrorRegister = false;
      this.messageErrorRegister = '';
      const { register } = this;
      if (
        !this.$v.register.$anyError &&
        this.$v.register.$anyDirty &&
        !datePickerV.$invalid
      ) {
        axios
          .post(REGISTER_URL, register)
          .then((res) => {
            if (res) {
              this.submitted = false;
              this.registered = true;
            } else {
              this.messageErrorRegister = this.$t(
                'login.register.errorMessages.generic'
              );
            }
          })
          .catch((error) => {
            this.registered = false;
            this.submitted = false;
            this.showErrorRegister = true;
            if (error && error.response) {
              if (error.response.status === 422) {
                if (error.response.data === 'Password does not match') {
                  this.messageErrorRegister = this.$t(
                    'login.register.errorMessages.passwordMustMatch'
                  );
                } else if (error.response.data === 'Invalid invite') {
                  this.messageErrorRegister = this.$t(
                    'login.register.errorMessages.inviteInvalid'
                  );
                } else if (
                  error.response.data ===
                  'Email does not match with Invited Email'
                ) {
                  this.messageErrorRegister = this.$t(
                    'login.register.errorMessages.inviteMailNoMatch'
                  );
                } else {
                  this.$rollbar.error('New error from the server not mapped.');
                  this.messageErrorRegister = this.$t(
                    'login.register.errorMessages.generic'
                  );
                }
              } else if (error.response.status === 400) {
                if (error.response.data === 'Missing parameters') {
                  this.messageErrorRegister = this.$t(
                    'login.register.errorMessages.allFieldsAreMandatory'
                  );
                } else if (error.response.data === 'Missing invite') {
                  this.messageErrorRegister = this.$t(
                    'login.register.errorMessages.inviteMandatory'
                  );
                } else {
                  this.$rollbar.error('New error from the server not mapped.');
                  this.messageErrorRegister = this.$t(
                    'login.register.errorMessages.generic'
                  );
                }
              } else if (error.response.status === 409) {
                this.messageErrorRegister = this.$t(
                  'login.register.errorMessages.emailAlreadyRegister'
                );
              } else if (error.response.status === 410) {
                this.messageErrorRegister = this.$t(
                  'login.register.errorMessages.inviteExpired'
                );
              } else if (error.response.status === 500) {
                this.messageErrorRegister = this.$t(
                  'login.register.errorMessages.generic'
                );
              }
            } else {
              this.messageErrorRegister = this.$t(
                'login.register.errorMessages.generic'
              );
              this.$rollbar.error('Error', error);
            }
          });
      } else {
        this.registered = false;
        this.submitted = false;
        this.showErrorRegister = true;
        if (!this.$v.register.$anyDirty) {
          this.messageErrorRegister = this.$t(
            'login.register.errorMessages.allFieldsAreMandatory'
          );
        } else {
          this.messageErrorRegister = this.$t(
            'login.register.errorMessages.fixFormFields'
          );
        }
      }
    },
    cleanError() {
      this.showErrorRegister = false;
      this.messageErrorRegister = '';
    },
    selectedDate(date) {
      this.register.dob = date;
    }
  },
  computed: {
    mdiEye() {
      return mdiEye;
    },
    mdiEyeOff() {
      return mdiEyeOff;
    },
    mdiPhone() {
      return mdiPhone;
    },
    registerNameValidation() {
      const errors: TranslateResult[] = [];
      if (!this.$v.register.name?.$dirty) return errors;
      !this.$v.register.name.required &&
        errors.push(this.$t('login.register.errorMessages.requiredField'));
      !this.$v.register.name.alpha &&
        errors.push(this.$t('login.register.errorMessages.shouldBeLetters'));
      return errors;
    },
    registerLastNameValidation() {
      const errors: TranslateResult[] = [];
      if (!this.$v.register.lastName?.$dirty) return errors;
      !this.$v.register.lastName.required &&
        errors.push(this.$t('login.register.errorMessages.requiredField'));
      !this.$v.register.lastName.alphaAndSpaces &&
        errors.push(this.$t('login.register.errorMessages.shouldBeLetters'));
      return errors;
    },
    registerEmailValidation() {
      const errors: TranslateResult[] = [];
      if (!this.$v.register.email?.$dirty) return errors;
      !this.$v.register.email.required &&
        errors.push(this.$t('login.register.errorMessages.requiredField'));
      !this.$v.register.email.email &&
        errors.push(this.$t('login.register.errorMessages.emailMustBeValid'));
      return errors;
    },
    registerPhoneValidation() {
      const errors: TranslateResult[] = [];
      const field = this.$v.register.phone as ValidationGroups & Validation;
      if (!field.$dirty) return [];
      !field.required &&
        errors.push(this.$t('login.register.errorMessages.requiredField'));
      !field.validFormat &&
        errors.push(this.$t('login.register.errorMessages.phoneMustBeValid'));
      return errors;
    },
    registerDoBValidation() {
      const errors: TranslateResult[] = [];
      if (!this.$v.register.dob?.$dirty) return errors;
      !this.$v.register.dob.required &&
        errors.push(this.$t('login.register.errorMessages.requiredField'));
      return errors;
    },
    registerPasswordValidation() {
      const errors: TranslateResult[] = [];
      if (!this.$v.register.password?.$dirty) return errors;
      !this.$v.register.password.required &&
        errors.push(this.$t('login.register.errorMessages.requiredField'));
      !this.$v.register.password.minLength &&
        errors.push(
          this.$t('login.register.errorMessages.passwordMinLength', {
            character: PASSWORD_MIN_LENGTH
          })
        );
      !this.$v.register.password.mustHaveAlphaCaps &&
        errors.push(this.$t('login.register.errorMessages.mustHaveAlphaCaps'));
      !this.$v.register.password.mustHaveAlphaNonCaps &&
        errors.push(
          this.$t('login.register.errorMessages.mustHaveAlphaNonCaps')
        );
      !this.$v.register.password.mustHaveNumber &&
        errors.push(this.$t('login.register.errorMessages.mustHaveNumber'));
      !this.$v.register.password.mustHaveSpecialChar &&
        errors.push(
          this.$t('login.register.errorMessages.mustHaveSpecialChar')
        );
      return errors;
    },
    registerPasswordConfValidation() {
      const errors: TranslateResult[] = [];
      if (!this.$v.register.passwordConfirmation?.$dirty) return errors;
      !this.$v.register.passwordConfirmation.required &&
        errors.push(this.$t('login.register.errorMessages.requiredField'));
      !this.$v.register.passwordConfirmation.sameAsPassword &&
        errors.push(this.$t('login.register.errorMessages.passwordMustMatch'));
      return errors;
    },
    registerInviteValidation() {
      const errors: TranslateResult[] = [];
      if (!this.$v.register.invite?.$dirty) return errors;
      !this.$v.register.invite.required &&
        errors.push(this.$t('login.register.errorMessages.requiredField'));
      return errors;
    }
  }
});
