import { loadPublicPlatformConfig } from '@/helpers/data/fauna-queries';
import { showError } from '@/helpers/utils';
import { ERROR_LIST } from '@/helpers/errors';

export type PublicPlatformConfiguration = {
  INVITE_ONLY: boolean;
};

class PublicPlatformConfig {
  private config: PublicPlatformConfiguration | undefined;

  constructor() {
    this.config = undefined;
  }

  public async get() {
    if (!this.config) {
      return loadPublicPlatformConfig()
        .then((configs) => {
          if (configs) {
            this.config = configs;
            return configs;
          } else {
            showError(ERROR_LIST.PLATFORM_CONFIGURATION_MISSING);
            return undefined;
          }
        })
        .catch(() => {
          showError(ERROR_LIST.PLATFORM_CONFIGURATION_MISSING);
          return undefined;
        });
    }

    return this.config;
  }
}

export const publicConfig = new PublicPlatformConfig();
