
import Vue from 'vue';
import axios from 'axios';
import { RESET_PASS_URL } from '@/store/constants';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;
import { validationMixin } from 'vuelidate';
import { mask } from 'vue-the-mask';
import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import {
  mustHaveAlphaCaps,
  mustHaveAlphaNonCaps,
  mustHaveNumber,
  mustHaveSpecialChar,
  PASSWORD_MIN_LENGTH
} from '@/helpers/validations';
import { mdiEye, mdiEyeOff } from '@mdi/js';

interface Data {
  reset: { password: string; passwordConf: string; code: string };
  confirmed: boolean;
  showError: boolean;
  submitted: boolean;
  form: boolean;
  showPasswordReg: boolean;
  showPasswordRegConf: boolean;
  messageError: TranslateResult;
}

interface Methods {
  resetPass(): void;
}

interface Computed {
  passwordValidation: TranslateResult[];
  passwordConfValidation: TranslateResult[];
  mdiEye: string;
  mdiEyeOff: string;
}

export default Vue.extend<Data, Methods, Computed, Record<string, never>>({
  name: 'resetPassword',
  mixins: [validationMixin],
  directives: { mask },
  validations: {
    reset: {
      password: {
        required,
        minLength: minLength(PASSWORD_MIN_LENGTH),
        mustHaveAlphaCaps,
        mustHaveAlphaNonCaps,
        mustHaveNumber,
        mustHaveSpecialChar
      },
      passwordConf: { required, sameAsPassword: sameAs('password') }
    }
  },
  data() {
    return {
      reset: {
        password: '',
        passwordConf: '',
        code: ''
      },
      confirmed: false,
      showError: false,
      form: true,
      submitted: false,
      showPasswordReg: false,
      showPasswordRegConf: false,
      messageError: ''
    };
  },
  methods: {
    resetPass() {
      this.submitted = true;
      if (!this.$v.reset.$anyError && this.$v.reset.$anyDirty) {
        this.reset.code = this.$router.currentRoute.params.code;
        axios
          .post(RESET_PASS_URL, this.reset)
          .then(() => {
            this.confirmed = true;
            this.showError = false;
            this.submitted = false;
            this.form = false;
          })
          .catch((error) => {
            this.confirmed = false;
            this.showError = true;
            this.submitted = false;
            if (error && error.response) {
              if (error.response.status === 422) {
                this.messageError = this.$t(
                  'resetPassword.errorMessages.passwordMustMatch'
                );
              } else if (error.response.status === 400) {
                this.messageError = this.$t(
                  'resetPassword.errorMessages.invalidLink'
                );
              } else if (error.response.status === 409) {
                this.messageError = this.$t(
                  'resetPassword.errorMessages.userNotExist'
                );
              } else if (error.response.status === 410) {
                this.messageError = this.$t(
                  'resetPassword.errorMessages.codeExpired'
                );
              } else if (error.response.status === 500) {
                this.messageError = this.$t(
                  'resetPassword.errorMessages.generic'
                );
              }
            } else {
              this.messageError = this.$t(
                'resetPassword.errorMessages.generic'
              );
              this.$rollbar.error('Error', error);
            }
          });
      } else {
        this.confirmed = false;
        this.showError = true;
        this.messageError = this.$t(
          'login.register.errorMessages.allFieldsAreMandatory'
        );
      }
    }
  },
  computed: {
    mdiEye() {
      return mdiEye;
    },
    mdiEyeOff() {
      return mdiEyeOff;
    },
    passwordValidation() {
      const errors: TranslateResult[] = [];
      if (!this.$v.reset.password?.$dirty) return errors;
      !this.$v.reset.password.required &&
        errors.push(this.$t('resetPassword.errorMessages.requiredField'));
      !this.$v.reset.password.mustHaveAlphaCaps &&
        errors.push(this.$t('resetPassword.errorMessages.mustHaveAlphaCaps'));
      !this.$v.reset.password.mustHaveAlphaNonCaps &&
        errors.push(
          this.$t('resetPassword.errorMessages.mustHaveAlphaNonCaps')
        );
      !this.$v.reset.password.mustHaveNumber &&
        errors.push(this.$t('resetPassword.errorMessages.mustHaveNumber'));
      !this.$v.reset.password.mustHaveSpecialChar &&
        errors.push(this.$t('resetPassword.errorMessages.mustHaveSpecialChar'));
      !this.$v.reset.password.minLength &&
        errors.push(
          this.$t('resetPassword.errorMessages.passwordMinLength', {
            character: PASSWORD_MIN_LENGTH
          })
        );
      return errors;
    },
    passwordConfValidation() {
      const errors: TranslateResult[] = [];
      if (!this.$v.reset.passwordConf?.$dirty) return errors;
      !this.$v.reset.passwordConf.required &&
        errors.push(this.$t('resetPassword.errorMessages.requiredField'));
      !this.$v.reset.passwordConf.sameAsPassword &&
        errors.push(this.$t('resetPassword.errorMessages.passwordMustMatch'));
      return errors;
    }
  }
});
